<template>
    <div id="report">
        <div class='report' id="pdfDom" ref="pdfDom">
            <!-- 用户信息 -->
            <userInfo :userData="userData" :headImg="headImg" id="user" class="target-node-item"></userInfo>
            <!-- 器官储备能力分析 -->
            <generalAnalysis :analysisData="analysisData"  class="target-node-item"></generalAnalysis>
            
            <!-- 分析 -->
            <div v-for="(item, i) in reportData" :key="i" class="target-node-item" :id="`analysis${i+2}`">
                <analysisTable :analysisTableData="item" v-if="'introduce' in item"></analysisTable>
                <generalAnalysis :analysisData="item"  v-else></generalAnalysis>
            </div>
        </div>
    </div>
</template>

<script>
import userInfo from './components/userInfo'
import generalAnalysis from './components/generalAnalysis.vue'
import analysisTable from './components/analysisTable.vue'
import { getCoreReport } from '@/api/index'

export default {
    components: {
        userInfo,
        generalAnalysis,
        analysisTable
    },
    data() {
        return {
            userData: {},
            headImg: `${this.$imgDomain}/www/HealthingCore/head2.png`,
            analysisData: {
                title: '器官储备能力分析',
                translate: 'ANALYSIS OF ORGAN RESERVE CAPACITY',
                img: `${this.$imgDomain}/www/HealthingCore/report11.png`,
                imgSize: true,
                text: '每个人都有一定量的器官储备能力，在出生时就被赋予了比自身所需水平高得多的功能，而且每个器官都是如此。你拥有的脑组织多于身体所需，而肌肉组织、肾脏组织、肝脏组织等也是如此。但是随着年龄的增长，大多数人失去了这种储备能力。然而，丧失储备能力的症状表现却并不突出，直到出现大批细胞受损的情况，器官功能才会明显减退。对于所有疾病来说，早在被诊断出来数年甚至数十年前，人体内就已经存在着致病元凶了，并逐渐导致细胞受损，它们分别是营养不良、代谢失调、细胞失衡、排毒功能受损和氧化应激等，只有清晰什么是真正导致你生病和影响器官储备能力下降的具体原因，在细胞层面上发现问题，通过从源头提前干预，我们就能维持器官功能，降低储备能力下降的速度，达到预防和改善慢病的目的。',
                leftBag: `${this.$imgDomain}/www/HealthingCore/bag1.png`,
                downBag: `${this.$imgDomain}/www/HealthingCore/bag4.png`,
            },
            reportData: [
                {
                    title: '氧化和抗氧化压力分析',
                    introduce: [
                        {
                            type: 'strong',
                            text: '什么是氧化压力分析？'
                        },
                        {
                            text: '抗衰老需要抗氧化，若体内缺乏抗氧化物，就会使自由基累积，氧化压力升高，可能会导致：氧化压力增加、疲劳症候群、加速老化、老化相关疾病风险增加(如类风湿性关节炎、阿兹海默症与帕金森氏症)白内障、糖尿病、冠状动脉硬化等心血管疾病、癌症。'
                        },
                        {
                            type: 'strong',
                            text: '什么是抗氧化维生素？'
                        },
                        {
                            text: '参与体内各种生化反应的重要因子，是维持正常生理功能的必需营养素。不同的维生素在体内新陈代谢中扮演着不同的角色，而能对抗氧化压力，预防身体过多的自由基伤害或消除已形成自由基的维生素就统称为“抗氧化维生素”'
                        },
                    ],
                    viewImg: `${this.$imgDomain}/www/HealthingCore/viewImg2.png`,
                    tebledata: []
                },
                {
                    title: '肝脏解毒功能分析',
                    introduce: [
                        {
                            text: '我们的肝脏就像一个大的污水处理厂，经过肠道摄入的、皮肤吸收的各种物质以及内源性代谢都需要经过肝脏I、II阶段的代谢，生成无毒的产物排出体外，这个过程需要消耗很多的能量，需要提供充足的代谢所需营养元素：如果毒素负荷过重、原料供应不足或肝脏代谢能力下降，就导致一些中间代谢产物的蓄积，肝脏也是身体最重要的防御系统，将体内的毒素分解成“中和”可溶性，当肝脏解毒功能下降会导致一系列健康问题，肝脏解毒功能分析找到导致肝脏解毒功能下降的核心原因。'
                        },
                    ],
                    viewImg: `${this.$imgDomain}/www/HealthingCore/viewImg4.png`,
                    tebledata: []
                },
                // {
                //     title: '雌激素代谢分析',
                //     introduce: [
                //         {
                //             text: '目前很多研究表明乳腺癌，子宫肌瘤的发生和雌激素代谢失衡有着密切的关系，雌激素水平过高或相对优势都可以诱导乳腺细胞过度增生。'
                //         },
                //         {
                //             text: '雌激素在体内发挥完作用后，需要在肝脏代谢灭活排出体外，如果肝脏解毒负荷过重或一些相关的酶先天缺陷，就会导致雌激素在肝脏I、II阶段的代谢失衡，导致风险型（促癌型）雌激素代谢产物增多，从而增加雌激素敏感肿瘤的风险。'
                //         },
                //         {
                //             text: '雌激素代谢失衡对健康的影响：月经紊乱、乳房胀痛、子宫肌瘤，乳腺增生，乳腺癌、卵巢囊肿、免疫紊乱，代谢紊乱，男性：精子质量下降或不育。'
                //         },
                //     ],
                //     viewImg: `${this.$imgDomain}/www/HealthingCore/viewImg8.png`,
                //     tebledata: []
                // },
                {
                    title: '肠道菌群健康分析',
                    introduce: [
                        {
                            text: '肠道菌群是健康的守护神，在我们的肠道，有很多的菌群结构，菌群的正常的状态维持肠道的一个生态平衡，对人体的健康有着很大的影响。'
                        },
                        {
                            type: 'strong',
                            text: '肠道菌群平衡几大作用:'
                        },
                        {
                            text: '1）帮助消化食物和吸收营养，菌群不平衡，便秘，便溏，消化不良'
                        },
                        {
                            text: '2）肠道也是重要的免疫器官，促进机体免疫提升'
                        },
                        {
                            text: '3）减少胆固醇吸收，减肥，预防心脑血管疾病'
                        },
                        {
                            text: '4）肠道也是人体第二个大脑，菌群不平衡影响大脑健康'
                        },
                    ],
                    viewImg: `${this.$imgDomain}/www/HealthingCore/viewImg.png`,
                    tebledata: []
                },
                // {
                //     title: '线粒体能量转换',
                //     introduce: [
                //         {
                //             text: '线粒体是人体的小型“发电站”，细胞生存所需的大部分能量是在线粒体中产生的，它们是生命的来源，它掌管细胞的衰老，因此，线粒体功能增强是非常重要的，可以增加能量水平，并减缓衰老速度，线粒体负责产生我们的细胞有效运作所需的能量 ATP。包括心脏跳动、肌肉收缩、保持体温及所有的生命活动，简而言之，没有ATP我们就会死亡。'
                //         },
                //         {
                //             text: '线粒体功能衰退的表现：疲倦或缺乏活力/荷尔蒙情绪波动/体重增加或血糖波动/恢复能力下降/皮肤老化/衰老速度快/压力或焦虑/睡眠困难/心脏，血压或循环问题/缺乏头脑敏锐度；'
                //         },
                //     ],
                //     tebledata: []
                // },
                // {
                //     title: '心理情绪分析',
                //     translate: 'PSYCHOLOGICAL AND EMOTIONAL ANALYSIS',
                //     img: `${this.$imgDomain}/www/HealthingCore/report12.png`,
                //     imgSize: true,
                //     text: '我们都知道不良的生活习惯是造成各种器官生病的重要原因，但却不知道其实情绪也会引起各种器官生病。事实上，心身医学认为我们情绪和躯体是一个整体，是一个相互关联、互为因果的整体。心理社会因素在疾病的发生和发展过程中起重要作用；许多疾病都属于心身疾病的范畴，例如：哮喘、肿瘤、糖尿病、心脏病、胃溃疡、头痛、慢性疲劳和高血压等，也就是现代常见的一些慢性疾病都可能属于心身疾病的范畴，都有心理影响的因素。通过一组美国职业安全与健康管理局(OSHA)的数据，可以更清楚的看到压力对于神经系统、内分泌系统和免疫系统的影响。43%的成年人遭受压力对健康的不利影响，75%到90%的就诊都是因为压力相关的疾病和抱怨，一生中情绪障碍的患病率超过50%，通常是由于慢性的、未经治疗的应激反应引起的。',
                //     leftBag: `${this.$imgDomain}/www/HealthingCore/bag1.png`,
                //     downBag: `${this.$imgDomain}/www/HealthingCore/bag4.png`,
                // },
                {
                    title: '环境激素毒素分析',
                    introduce: [
                        {
                            text: '环境荷尔蒙又称为内分泌干扰素“山寨荷尔蒙”，入侵者在我们的生活中广泛存在，因为它会模拟人体天然的荷尔蒙，出现以假乱真的现象，故而会导致机体的内分泌系统误认为这是自己人而加以放心，入侵者就会占据人体细胞正常荷尔蒙的位置，与雌激素受体结合，从而引发内分泌紊乱，造成人体正常激素调节失常，进而影响生理调节机能。'
                        },
                        {
                            text: '主要来源：塑化剂，乳化剂，农药，家具，香水，化妆品，清洁剂，沐浴露...'
                        },
                    ],
                    viewImg: `${this.$imgDomain}/www/HealthingCore/viewImg5.png`,
                    tebledata: []
                },
                {
                    title: '重金属代谢分析',
                    introduce: [
                        {
                            text: '目前，随着环境污染的日益恶化，重金属已开始成为困扰国人健康的因素之一，虽然看不见，摸不到，但重金属在人体内慢慢蓄积，最终会引发各种慢性疾病，重金属的来源：空气污染，水污染，土壤污染，食物污染，重金属已在国人身体中慢慢累积，正侵蚀着我们的健康，而我们却没真正的认识到它的危害，重金属慢性蓄积导致的临床表现也多种多样，其中神经精神症状最常见，比如：偏头痛、焦虑、失眠、抑郁；其他不特异症状：免疫力低下、反复感染，慢性疲劳、皮肤过敏、自身免疫病、不孕不育甚至癌症等等。'
                        }
                    ],
                    viewImg: `${this.$imgDomain}/www/HealthingCore/viewImg6.png`,
                    tebledata: []
                },
                {
                    title: '营养元素健康分析',
                    introduce: [
                        {
                            text: '在地球所有的生命体中，人类是最符合自然规律的生物，经过非常久远的时间才发展成为了现在的人，而人体的重要组织除了水、血液等之外，还有一个重要的原因，就是体内所需要的微量元素，它是我们身体必不可少的营养元素，有了多种元素的摄取，才能维持我们身体的抵抗力和人体各个器官的正常活动。人体中的微量元素不但维持正常生理功能，而且它们在人体中含量的多少也会影响到人的智力、情绪等，是人类心理健康的物质基础。其中以钙、镁、锌、铁、碘、硒等元素对人的心理健康最为重要。'
                        },
                    ],
                    viewImg: `${this.$imgDomain}/www/HealthingCore/viewImg7.png`,
                    tebledata: []
                },
            ],
            loadding: false,
        };
    },
    props:{
        
    },
    created() {
        
    },
    mounted() {
        this.getCoreReportData();
    },
    methods: {
        getCoreReportData(){
            // this.loadding = true;
            const formData = new FormData();
        
            const id = this.$query('id')
            formData.append('action', 'GetTotalReportByAutoId');
            formData.append('id', id);
            getCoreReport(formData).then(res => {
                if(res.result) {
                    // 用户数据
                    this.userData = res.result.userExpands;
                    this.dataProcessing(res.result.coreReportInfo);
                } else {
                    this.$toast('无数据')
                }
                // this.loadding = false;
            })
        },
        dataProcessing(data){
            const reportData = this.reportData;
            reportData.forEach((el, index) => {
                if(el.tebledata) {
                    data.forEach(item => {
                        item.num = Number(item.Ex2.split('%')[0])
                        if(item.MainId.indexOf(el.title) != -1 && item.num > 35){
                            item.bagurl = 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/20221009145820.png';
                            el.tebledata.push(item)
                        }
                        // if(item.num > 35){
                        //     item.bagurl = 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/20221009145820.png';
                        // } else {
                        //     item.bagurl = 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/greenStripe.png';
                        // }
                    })
                }
            })
        },
    },
    watch: {
        
    },
    computed: {
        
    },
}
</script>
<style lang='scss' scoped>
#report {
    width: 100%;
}
.report {
    width: 592.28px;
    // width: 100%;
    height: 100%;
    padding: 10px;
    color: #474645;
    font-variant:normal !important;
}
.dowmload {
    width: 100%;
    text-align: center;
    padding: 20px 0;
}
</style>